import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'

export default function pay() {
  return (
    <Layout>
      <Helmet>
        <script src="https://payhere.co/embed/embed.js"></script>
      </Helmet>
      <Wrapper>
        <Heading>Pay your deposit and get started now</Heading>
        <Text>
          Flexatal 100% refundable Deposit We require a £299 deposit, which will
          be credited to your first invoice. Should you decide not to progress,
          you will be refunded in full.
        </Text>
        <Button
          color="rose"
          data-payhere-embed="https://payhere.co/flexatal-ltd/flexatal-100-refundable-deposit"
          round
          size="lg"
          style={{ width: 270 }}
        >
          Pay Now
        </Button>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  background-color: #232323;
  display: flex;
  min-height: 70vh;
  align-items: center;
  padding: 5%;
  flex-direction: column;
`

const Heading = styled.h1`
  font-size: 56px;
  font-weight: bold;
  padding-bottom: 2%;
  color: #ff9000;
`

const Text = styled.p`
  color: #fff;
  font-size: 24px;
  padding-bottom: 2%;
`
